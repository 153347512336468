body {
  width:100%;
  height:100%;
}

#root {
  width:100%;
  height:100%;
}

.App {
  text-align: center;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: white;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

.flex {
  display: flex;
}

.flex.row {
  flex-direction: row;
}

.flex.column {
  flex-direction: column;
}

.btn {
  background-color: #19a5e6;
  color: white;
  font-weight: bold;
  font-size: 1.5em;
  border-width: 0px;
  text-align: center;
}

.roomheader {
  width:100%;

  background-color: #000;
}

.roomheader .menuitem {
  color: #000;
  background-color: #aaa;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 20px;
  border: none;
  bottom: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  height: 40px;
}

.roomheader .menuitem.selected {
  color: #fff;
  background-color: #282c34;
  margin-top: 5px;
  height: 45px;
}

.roomheader .menulabel {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  margin-left: 20px;
  margin-right: 20px;
}

p {
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}

.bold {
  font-weight: bold;
}
